import React, { useState, useEffect } from 'react'
import getFirebase, { auth } from '../../utils/firebase'
import styles from './Login.module.scss'

export default () => {
  const firebase = getFirebase()
  // const auth = firebase.auth()

  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorPassword, setErrorPassword] = useState('')

  const handleSignIn = e => {
    e.preventDefault()
    let resValidateEmail = validateEmail()
    let resValidatePassword = validatePassword()

    const loginForm = document.querySelector('#login-form')

    if (!resValidateEmail || !resValidatePassword) {
      // Get user info
      const email = loginForm['login-email'].value
      const password = loginForm['login-password'].value

      auth.signInWithEmailAndPassword(email, password).then(() => {
        loginForm.style.display = 'none'
      })
    }
  }

  const validateEmail = e => {
    if (email === '') {
      setErrorEmail('Please input your email')
      return false
    } else {
      if (email.length >= 50) {
        setErrorEmail('50 letters maximum')
        return false
      }
      if (email.length < 6) {
        setErrorEmail('6 letters minimum')
        return false
      }
      setErrorEmail('')
    }
  }

  const validatePassword = e => {
    if (password === '') {
      setErrorPassword('Please input your password')
      return false
    } else {
      if (email.length >= 50) {
        setErrorPassword('50 letters maximum')
        return false
      }
      if (email.length < 6) {
        setErrorPassword('6 letters minimum')
        return false
      }
      setErrorPassword('')
    }
  }

  return (
    <form
      id="login-form"
      onSubmit={handleSignIn}
      className={styles.root}
      autoComplete="off"
    >
      <div className={styles.inputField}>
        <input
          type="email"
          id="login-email"
          placeholder="Email"
          className={errorEmail ? styles.errorInput : ''}
          onBlur={validateEmail}
          onChange={e => {
            setEmail(e.target.value)
          }}
          required
        />
        {errorEmail && <p className={styles.errorText}>{errorEmail}</p>}
      </div>
      <div className={styles.inputField}>
        <input
          type="password"
          id="login-password"
          placeholder="Password"
          className={errorPassword ? styles.errorInput : ''}
          onBlur={validatePassword}
          onChange={e => {
            setPassword(e.target.value)
          }}
          required
        />
        {errorPassword && <p className={styles.errorText}>{errorPassword}</p>}
      </div>
      <div className={styles.button}>
        <button className={styles.button__item}>Login</button>
      </div>
    </form>
  )
}
