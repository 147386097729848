import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyA6BOhslFLfBkiTlJlxw25nJgDax-b9xrk',
  authDomain: 'ayaoswork.firebaseapp.com',
  databaseURL: 'https://ayaoswork.firebaseio.com',
  projectId: 'ayaoswork',
  storageBucket: 'ayaoswork.appspot.com',
  messagingSenderId: '1052091447921',
  appId: '1:1052091447921:web:b4de2eb936196a631ef9d0',
  measurementId: 'G-45BVX871B5',
}

let instance, firestore

export let auth

// Initialize Firebase (Gatsby compatible version)
function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance
    instance = firebase.initializeApp(firebaseConfig)
    auth = firebase.auth()
    firestore = firebase.firestore()
    return instance
  }

  return null
}

export default getFirebase
