import React from 'react'
import Layout from '../components/Layout/Layout'
import Portfolio from '../components/Portfolio/Portfolio'

export default () => {
  return (
    <Layout>
      <Portfolio />
    </Layout>
  )
}
