import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import styles from './WorkList.module.scss'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageOisixUi: file(relativePath: { eq: "portfolio-oisix-ui.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageHotahikaUi: file(relativePath: { eq: "portfolio-hotahika-ui.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMisawaya: file(relativePath: { eq: "portfolio-misawaya.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEY: file(relativePath: { eq: "portfolio-ey.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCineric: file(relativePath: { eq: "portfolio-cineric.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNaming: file(relativePath: { eq: "portfolio-naming.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSankeibt: file(relativePath: { eq: "portfolio-sankeibt.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCinericjp: file(relativePath: { eq: "portfolio-cinericjp.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTempura: file(relativePath: { eq: "portfolio-tempura.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageKCH: file(relativePath: { eq: "portfolio-kch.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imagePhilosofia: file(relativePath: { eq: "portfolio-philosofia.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMeiji: file(relativePath: { eq: "portfolio-meiji.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageYamaki: file(relativePath: { eq: "portfolio-yamaki.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      imageAR: file(relativePath: { eq: "portfolio-ex-ar.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageParallax: file(relativePath: { eq: "portfolio-ex-parallax.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageRA: file(relativePath: { eq: "portfolio-ra.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageHineru: file(relativePath: { eq: "portfolio-hineru.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFS: file(relativePath: { eq: "portfolio-fs.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCodepen: file(relativePath: { eq: "portfolio-codepen.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      imageHotahikaXmas: file(
        relativePath: { eq: "portfolio-hotahika-xmas.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageHotahikaFathersday: file(
        relativePath: { eq: "portfolio-hotahika-fathersday.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageHotahikaAutumn: file(
        relativePath: { eq: "portfolio-hotahika-autumn.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOisixEasy: file(relativePath: { eq: "portfolio-oisix-easy.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageShirt: file(relativePath: { eq: "sample_shirt2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample1: file(relativePath: { eq: "portfolio-others_sample1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample2: file(relativePath: { eq: "portfolio-others_sample2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample3: file(relativePath: { eq: "portfolio-others_sample3.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample4: file(relativePath: { eq: "portfolio-others_sample4.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample5: file(relativePath: { eq: "portfolio-others_sample5.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample6: file(relativePath: { eq: "portfolio-others_sample6.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample7: file(relativePath: { eq: "portfolio-others_sample7.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample8: file(relativePath: { eq: "portfolio-others_sample8.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample9: file(relativePath: { eq: "portfolio-others_sample9.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample10: file(relativePath: { eq: "portfolio-others_sample10.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample11: file(relativePath: { eq: "portfolio-others_sample11.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample12: file(relativePath: { eq: "portfolio-others_sample12.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample13: file(relativePath: { eq: "portfolio-others_sample13.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample14: file(relativePath: { eq: "portfolio-others_sample14.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample15: file(relativePath: { eq: "portfolio-others_sample15.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sample16: file(relativePath: { eq: "portfolio-others_sample16.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 350) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className={styles.root}>
      <div className="container-fluid">
        <ul className={styles.menu}>
          {/* <li>CONTENTS</li> */}
          <li>
            <a href="#featured">
              Featured Projects{' '}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <polygon points="48.15 16.35 43.78 11.98 25 30.8 6.22 11.98 1.85 16.35 20.63 35.13 25 39.5 48.15 16.35" />
              </svg>
            </a>
          </li>
          <li>
            <a href="#website">
              Websites/E-Commerce{' '}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <polygon points="48.15 16.35 43.78 11.98 25 30.8 6.22 11.98 1.85 16.35 20.63 35.13 25 39.5 48.15 16.35" />
              </svg>
            </a>
          </li>
          <li>
            <a href="#personal">
              Personal Projects{' '}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <polygon points="48.15 16.35 43.78 11.98 25 30.8 6.22 11.98 1.85 16.35 20.63 35.13 25 39.5 48.15 16.35" />
              </svg>
            </a>
          </li>
          <li>
            <a href="#design">
              Other Works{' '}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <polygon points="48.15 16.35 43.78 11.98 25 30.8 6.22 11.98 1.85 16.35 20.63 35.13 25 39.5 48.15 16.35" />
              </svg>
            </a>
          </li>
        </ul>
      </div>

      <div id="featured" className="container-fluid">
        <h2 className={styles.heading}>Featured Projects</h2>
      </div>

      {/* <Tabs defaultIndex={0}>
        <div className="tab-list__wrapper font-headline">
          <TabList>\
            <Tab>Featured Works</Tab>
            <Tab>Websites/E-Commerce</Tab>
            <Tab>EC Design</Tab>
            <Tab>Personal Projects</Tab>
          </TabList>
        </div>

        <div className="container-fluid">
          <TabPanel>1</TabPanel>
          <TabPanel>2</TabPanel>
          <TabPanel>3</TabPanel>
          <TabPanel>4</TabPanel>
        </div>
      </Tabs> */}

      <div className="container-fluid">
        <ul className={styles.picks}>
          <li className={styles.picks__list}>
            <Img
              fluid={data.imageMisawaya.childImageSharp.fluid}
              alt=""
              className={styles.picks__image}
            />
            <div className={styles.picks__text}>
              <div className={styles.picks__no}>PROJECT #1</div>
              <div className={styles.picks__copy}>
                きっかけを生み出すコミュニティづくり
              </div>
              <h3 className={styles.picks__title}>古民家 箕澤屋(みさわや)</h3>
              <div className={styles.picks__date}>2016-09 － Present</div>
              <div className={styles.picks__caption}>
                <p>
                  江戸末期に建てられた当時の建築様式を残す貴重な建物「箕澤屋」。家族の所有物として存在することを2014年に知り、保存活動を担うことを決意。代表として、古民家保存につながるあらゆる活動を行う。
                </p>
                <p className={styles.picks__box}>
                  <b>【施策/実施事項】</b>
                  建物改修プラン、事業企画、イベント主催、商品開発、商品コラボ、広報、デジタルマーケティング、カフェ運営、Webサイト構築、ロゴ・チラシ・Tシャツデザイン、ブログ、SNS発信、コンテンツ開発、会員サイト開発など
                </p>
                <h4>課題</h4>
                <p>
                  高齢化して管理が難しくなった古民家をどのように保存維持するか。
                </p>
                <h4>成果</h4>
                <p>
                  自身の得意分野であるWebを生かして、積極的に情報発信。徐々に地域での知名度をあげ、2017年から始めた夏のイベントが2019年には約300人集まり、駐車場が常時満車となるまでに成長した。
                </p>
                <p>
                  コロナの影響で2020年はすべて中止としたが、現在はオンラインに力を入れ、会員向けサービス、コンテンツ開発を進めている。
                </p>
                <a
                  href="https://misawaya.org/jp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://misawaya.org/
                </a>
              </div>
            </div>
          </li>

          <li className={styles.picks__list}>
            <Img
              fluid={data.imageOisixUi.childImageSharp.fluid}
              alt=""
              className={styles.picks__image}
            />
            <div className={styles.picks__text}>
              <div className={styles.picks__no}>PROJECT #2</div>
              <div className={styles.picks__copy}>
                香港へ食の安心安全を届ける
              </div>
              <h3 className={styles.picks__title}>Oisix Hong Kong</h3>
              <div className={styles.picks__date}>2012-09 － 2016-02</div>
              <div className={styles.picks__caption}>
                <p>
                  Webデザイナーとして業務に携わり、ユーザーが使いやすく購入したくなるオンラインショップを目指し、様々な施策に取り組む。
                </p>
                <p className={styles.picks__box}>
                  <b>【施策/実施事項】</b>
                  基本構築、UI改善、UI機能開発、AB実験、キャンペーンページ、新規獲得フロー改善、撮影プラン構築、スマホ最適化、運用効率化、英語サイト構築、上海向けサイトモックアップ構築、カスタマーインタビュー、展示会出展など
                </p>
                <h4>課題</h4>
                <p>
                  とりあえず商品を販売しているけれど、売上が全くあがらない状況。
                </p>
                <h4>成果</h4>
                <p>
                  就業開始当初は、ECサイトの基本もできておらず売上月商200万に満たなかったサイトを徐々に改善していき、半年で4倍、2016年には4000万を超える規模まで拡大し、事業成長の一端を担うことができた。
                </p>
              </div>
            </div>
          </li>

          <li className={styles.picks__list}>
            <Img
              fluid={data.imageNaming.childImageSharp.fluid}
              alt=""
              className={styles.picks__image}
            />
            <div className={styles.picks__text}>
              <div className={styles.picks__no}>PROJECT #3</div>
              <div className={styles.picks__copy}>
                映像プロダクションワーカーのためのお役立ちツール
              </div>
              <h3 className={styles.picks__title}>Post Production App</h3>
              <div className={styles.picks__date}>2020 － Present</div>
              <div className={styles.picks__caption}>
                <p>
                  個人プロジェクトで開発した映像プロダクション向けWebツール。
                </p>
                <p>
                  第一弾として、Netflix納品時の複雑な命名規則を簡単に生成することができるジェネレーターを開発。ニッチ層向けだが、LinkedIn関係者層の注目度は高く、公開してから数は少ないながらも定期的に世界からアクセスあり。
                </p>
                <p>現在は、第二弾のアプリケーションを開発中。</p>
                <a
                  href="https://postproduction.app/netflix-deliverable-naming"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://postproduction.app/
                </a>
                <p className={styles.picks__box}>
                  <b>【技術/ツール】</b>
                  HTML/CSS、React/Gatsby、JavaScript、Netlify、Git
                </p>
              </div>
            </div>
          </li>
        </ul>

        <h2 id="website" className={styles.heading}>
          Websites/E-Commerce
        </h2>
        <ul className={styles.lists}>
          <li className={styles.list}>
            <Img
              fluid={data.imageRA.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
            <h3 className={styles.item__title}>RA活動報告会ONLINE</h3>
            <p className={styles.item__caption_jp}>
              コロナ禍によって実施できなくなったイベントの代わりとなる動画配信サイト。レスポンシブ、かつターゲットの幅広い年齢の方が使いやすいUIを意識して制作。
            </p>
            <div className={styles.item__tag}>
              <span className={styles.design}>UI Design</span>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>HTML / CSS / JavaScript</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://hineru.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageHineru.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
            </a>
            <h3 className={styles.item__title}>ヒネル株式会社</h3>
            <p className={styles.item__caption_jp}>
              下北沢で飲食ビジネスを展開する企業サイト。特徴あるロゴだったためアニメーションを提案＆実装。
            </p>
            <div className={styles.item__tag}>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>HTML / CSS / JavaScript</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://fumirosato.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageFS.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>Fumiro Sato</h3>
            </a>
            <p className={styles.item__caption_jp}>
              NYで活躍する映画監督のWebサイト。モーダル機能を独自開発。
            </p>
            <div className={styles.item__tag}>
              <span className={styles.design}>UI Design</span>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>JavaScript / React / Gatsby</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://emaexplorations.com/en/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageEY.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>Ema Ryan Yamazaki</h3>
            </a>
            <p className={styles.item__caption_jp}>
              日本とNYで活躍する映画監督のWebサイト。日本と世界、両方に最適にアピールすべくSEO対策でどちらの言語でも適切な言語ページが表示されるよう構築。
              {/* A documentary film director multi-language website */}
            </p>
            <div className={styles.item__tag}>
              <span className={styles.design}>UI Design</span>
              <span className={styles.dev}>Development</span>
              <span className={styles.seo}>SEO</span>
            </div>
            <div className={styles.item__tech}>JavaScript / React / Gatsby</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://cinericcreative.com/en/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageCineric.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>Cineric Creative</h3>
            </a>
            <p className={styles.item__caption_jp}>
              映像プロダクションWebサイト。グローバル企業として、ブランディングと信頼につながる英語サイト立ち上げ希望を受け、強みである映画作品をメインに見せた構築を行う。
              {/* An independent film production company's website */}
            </p>
            <div className={styles.item__tag}>
              <span className={styles.design}>UI Design</span>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>JavaScript / React / Gatsby</div>
          </li>

          <li className={styles.list}>
            <a
              href="http://hotarunohikari.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageHotahikaUi.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
            </a>
            <h3 className={styles.item__title}>Hotaru no Hikari</h3>
            <p className={styles.item__caption_jp}>
              ギフトおつまみオンラインショップ。ECでの売上UPに力を入れたいとの希望で、UI改善として、ユーザビリティ、運用効率UP施策を行う。
            </p>
            <div className={styles.item__tag}>
              <span className={styles.design}>UI Design</span>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>
              HTML / CSS / JavaScript / Makeshop
            </div>
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.imageSankeibt.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
            <h3 className={styles.item__title}>Sankei Bldg Techno</h3>
            <p className={styles.item__caption_jp}>
              企業Webサイトレスポンシブコーディング。
            </p>
            {/* <p className={styles.item__caption}>Construction company's website</p> */}
            <div className={styles.item__tag}>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>HTML / CSS / JavaScript</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://tempura-tokyo.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageTempura.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>Tempura Tokyo</h3>
            </a>
            <p className={styles.item__caption_jp}>
              ギフト菓子ブランドのブランディングサイトデザイン、及びコーディング。
            </p>
            {/* <p className={styles.item__caption}>
            Japanese-style snack brand website
          </p> */}
            <div className={styles.item__tag}>
              <span className={styles.design}>UI Design</span>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>JavaScript / React / Gatsby</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://cineric.jp/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageCinericjp.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>Cineric JP</h3>
            </a>
            <p className={styles.item__caption_jp}>
              映像プロダクション企業の日本向けサイト。検索エンジン対策のため、WordPressでCMS対応を行う。
            </p>
            {/* <p className={styles.item__caption}>
            Film production company's Japanese website
          </p> */}
            <div className={styles.item__tag}>
              <span className={styles.design}>UI Design</span>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>
              HTML / CSS / JavaScript / WordPress
            </div>
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.imageKCH.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
            <h3 className={styles.item__title}>KCH</h3>
            <p className={styles.item__caption_jp}>
              学生向けコミュニティハウスLPコーディング。
            </p>
            {/* <p className={styles.item__caption}>Community house landing page</p> */}
            <div className={styles.item__tag}>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>HTML / CSS / JavaScript</div>
          </li>

          {/* <li className={styles.list}>
          <Img
            fluid={data.imagePhilosofia.childImageSharp.fluid}
            alt=""
            className={styles.item__image}
          />
          <h3 className={styles.item__title}>Philosofia</h3>
          <p className={styles.item__caption}>Community house landing page</p>
          <div className={styles.item__tag}>
            <span className={styles.dev}>Development</span>
          </div>
          <div className={styles.item__tech}>HTML5 / CSS3 / JavaScript</div>
        </li> */}

          <li className={styles.list}>
            <Img
              fluid={data.imageMeiji.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
            <h3 className={styles.item__title}>Meiji Yasuda Campaign</h3>
            <p className={styles.item__caption_jp}>
              明治安田生命キャンペーン、スマホサイト向けのフォームコーディング。
            </p>
            {/* <p className={styles.item__caption}>
            Insurance company's campaign page
          </p> */}
            <div className={styles.item__tag}>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>HTML / CSS / JavaScript</div>
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.imageYamaki.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
            <h3 className={styles.item__title}>Yamaki Online Shop</h3>
            <p className={styles.item__caption_jp}>
              ヤマキオンラインショップリニューアル。ECプラットフォームに合わせたコーディングを行う。
            </p>
            <div className={styles.item__tag}>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>
              HTML / CSS / JavaScript / CMS
            </div>
          </li>
        </ul>

        <h2 id="personal" className={styles.heading}>
          Personal Projects
        </h2>
        <ul className={styles.lists}>
          <li className={styles.list}>
            <a
              href="https://codepen.io/ayaos/pen/jOMezEv"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageCodepen.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>Web Animation @ Codepen</h3>
            </a>
            <p className={styles.item__caption_jp}>
              SVGやWebアニメーションの理解度を高めるためにはじめたCodepenでの開発。
            </p>
            <div className={styles.item__tag}>
              <span className={styles.animation}>Animation Design</span>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>JavaScript / CSS / HTML</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://ar-mushroom.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageAR.childImageSharp.fluid}
                alt=""
                Reality
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>AR Mushroom</h3>
            </a>
            <p className={styles.item__caption_jp}>
              iPhone向けAR Quick Lookでどこまで動作コントロールできるかの実験。
              {/* AR Quick Look Experiment */}
            </p>
            <div className={styles.item__tag}>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>Reality Composer</div>
          </li>

          <li className={styles.list}>
            <a
              href="https://mushroom-parallax.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.item__link}
            >
              <Img
                fluid={data.imageParallax.childImageSharp.fluid}
                alt=""
                className={styles.item__image}
              />
              <h3 className={styles.item__title}>Mushroom Parallax</h3>
            </a>
            <p className={styles.item__caption_jp}>
              スマホのDeviceOrientation実験。3Dでのコントロールを目指す中での第一段階として行った2Dアニメーション実装。
              {/* Parallax Animation based on Device Orientation */}
            </p>
            <div className={styles.item__tag}>
              <span className={styles.dev}>Development</span>
            </div>
            <div className={styles.item__tech}>JavaScript</div>
          </li>
        </ul>

        <h2 id="design" className={styles.heading}>
          Other Works
        </h2>
        <ul className={styles.others1}>
          <li className={styles.list}>
            <Img
              fluid={data.imageHotahikaFathersday.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.imageHotahikaAutumn.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.imageHotahikaXmas.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.imageShirt.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>
        </ul>

        <ul className={styles.others2}>
          <li className={styles.list}>
            <Img
              fluid={data.sample1.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample2.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample3.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample4.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample5.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample6.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample7.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample8.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample9.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample10.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample11.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>

          <li className={styles.list}>
            <Img
              fluid={data.sample12.childImageSharp.fluid}
              alt=""
              className={styles.item__image}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}
