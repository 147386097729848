import React, { useEffect } from 'react'
import { auth } from '../../utils/firebase'
import styles from './Logout.module.scss'

export default ({ setUser }) => {
  // const auth = firebase.auth()

  useEffect(() => {
    // Logout
    const logout = document.querySelector('#logout-button')
    logout.addEventListener('click', e => {
      e.preventDefault()
      auth.signOut().then(() => {
        setUser('')
      })
    })
  })

  return (
    <div className={styles.button}>
      <button id="logout-button" className={styles.button__text}>
        Logout
      </button>
    </div>
  )
}
