import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import getFirebase, { auth } from '../../utils/firebase'
import Login from './Login'
import WorkList from './WorkList'
import Logout from './Logout'
import styles from './Portfolio.module.scss'
import Resume from '../../assets/pdf/resume_202102.pdf'

const Portfolio = () => {
  const firebase = getFirebase()
  // const auth = firebase.auth()
  const [user, setUser] = useState('')

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user !== null) {
        setUser(user.uid)
      } else {
        setUser('')
      }
    })
  })

  return (
    <div className={styles.root}>
      <div className="container-fluid">
        <h1 className={styles.heading}>Ayana Osawa's Portfolio</h1>
      </div>
      {user ? (
        <>
          <div className="container-fluid">
            <div className={styles.lead}>
              <p className={styles.lead__title}>
                UI/UX Designer / Frontend Developer
              </p>
              {/* <p>
                Ayana is a front-end designer with 14+ years of experience in
                design, development, and organizing/managing projects based in
                New York and Nagano, Japan. The strong UI/UX skills with
                technical knowledge and deep understanding of entire businesses
                bring problem-solving to companies’ services and products.
              </p> */}
              <p>
                効果的なデザインのためには技術力とデータが必須との考えから、技術とデータを元にした機能的なUI/UXを大切にして、制作に取り組んでいます。制作業務の一方で、自身のプロジェクトとして、新しい技術を取り入れた開発や、古民家の保存活動を通じたコミュニティづくりを積極的に行い、サービスとビジネスのあり方、ユーザー体験の可能性を常に探求しています。
              </p>
              {/* <p>
                効果的で機能的なUI/UXを大切にしているフロントエンドデザイナー。制作業務の一方で、自身のプロジェクトとして、新しい技術の取り入れたアプリ開発や、古民家保存活動のコミュニティづくりといった活動も積極的に行い、ビジネスのあり方とユーザー体験の可能性を常に探求しています。
              </p> */}

              <div className={styles.link}>
                <a
                  href={Resume}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.link__external} nohover`}
                >
                  職務経歴書
                  <svg
                    id="icon"
                    data-name="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30.73 30.73"
                  >
                    <defs></defs>
                    <path d="M23.44,17.07v8.17A2.72,2.72,0,0,1,20.73,28h-15A2.72,2.72,0,0,1,3,25.25H3v-15A2.72,2.72,0,0,1,5.71,7.52h8.18" />
                    <path d="M19.35,3.43h8.18v8.18" />
                    <path d="M12.53,18.43l15-15" />
                  </svg>
                </a>
                Pass: jobs21
              </div>
            </div>
          </div>

          <WorkList />

          {/* <div className={styles.description}>
            <h3>My Story</h3>
          </div> */}

          <div className={styles.description} style={{ display: 'none' }}>
            <h3>What I do</h3>
            <p style={{ marginBottom: '0.5rem' }}>
              <b>Creative Dev/Design</b> - Front-end creative development for
              websites/web apps focusing on functional UI using HTML/CSS,
              JavaScript, React/Gatsby.
            </p>
            <p>
              <b>Digital Marketing Consulting</b> - Digital marketing/e-commerce
              consultation and technical advisory for small companies and
              owners.
            </p>

            <h3>Specialty</h3>
            <ul className={styles.description__specialty}>
              <li>UI/UX Design</li>
              <li>Responsive Coding</li>
              <li>E-commerce Design</li>
              <li>Micro Interaction</li>
              {/* <li>Internationalization</li> */}
            </ul>

            <h3>Skills/Tools</h3>
            <ul className={styles.description__tools}>
              <li>HTML5</li>
              <li>CSS3</li>
              <li>Sass</li>
              <li>JavaScript</li>
              <li>React</li>
              <li>Gatsby</li>
              <li>GSAP</li>
              <li>three.js</li>
              <li>TypeScript</li>
              <li>Git</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>XD</li>
              <li>Figma</li>
            </ul>
          </div>
          <div className="container-fluid">
            <Logout setUser={setUser} />
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid">
            <p className={styles.lead}>Please Login to see my portfolio.</p>
            <Login />
          </div>
        </>
      )}
      <div className="container-fluid">
        <Link to="/" className={styles.button}>
          ← Go to home
        </Link>
      </div>
    </div>
  )
}
export default Portfolio
